main{
	aside{
		div.sidebar_nav{
			margin-bottom: 20px;
			ul{
				@extend .no-list;
				li{
					position: relative;
					margin-bottom: 1px;
					padding-left: 25px;
					background-color: $ccorp;
					&:before,
					&:after{
						display: block;
						position: absolute;
						content: "";
						width: 10px;
						height: 1px;
						left: 6px;
						top: 14px;
						transform: rotate(-45deg);
						background-color: $cwhite;
					}
					&:after{
						left: 10px;
						top: 18px;
					}
					a{
						display: flex;
						align-items: center;
						flex-direction: flex-start;
						position: relative;
						height: 35px;
						padding-left: 15px;
						color: $cbase;
						background-color: $cwhite;
					}
					&.active{
						background-color: $cmain;
						a{
							background-color: $clight;
							&:before{
								@include triangle(35px 5px, $cmain, right);
								display: block;
								position: absolute;
								content: "";
								left: 0;
								top: 0;
							}
						}
					}
					&:hover{
						a{
							background-color: $clight;
					
						}
					}
				}
			}
		}
		div.banners{
			margin-bottom: 20px;
			ul{
				@extend .no-list;
				li{
					margin-bottom: 10px;
					a{
						display: flex;
						align-items: center;
						justify-content: flex-start;
						position: relative;
						height: 70px;
						padding-left: 17px;
						color: $cbase;
						&:before,
						&:after{
							display: block;
							position: absolute;
							content: "";
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background: url(../../themes/images/banner_bak.png) left top no-repeat;
							transition: all 0.4s ease;
						}
						&:after{
							opacity: 0;
							background: url(../../themes/images/banner_bak_hover.png) left top no-repeat;
						}
						&:hover{
							&:before{
								opacity: 0;
							}
							&:after{
								opacity: 1;
							}
						}
						span{
							display: block;
							width: 40px;
							text-align: center;
							z-index: 2;
						}
						strong{
							margin-left: 15px;
							font-size: $fsbigger;
							text-transform: uppercase;
							font-weight: normal;
							z-index: 2;
						}
					}
				}
			}
		}
	}
}