section.front_blocks{
	margin-bottom: 40px;
	div.main_slider{
		div.slick-slide{
			h3{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				position: absolute;
				height: 30px;
				left: 0;
				top: 30px;
				padding-left: 50px;
				padding-right: 10px;
				font-size: $fsbigger;
				text-transform: uppercase;
				background-color: rgba(255,255,255,0.4);
			}
			div.slide_desc{
				height: 115px;
				padding: 12px 15px;
				line-height: 1.25;
				background-image: url(../../themes/images/slider_bak.png);
				a{
					color: $cbase;
					transition: all 0.4s ease;
					&:hover{
						color: $ccorp;
					}
				}
			}
		}
		ul.slick-dots{
			@include clearfix;
			@extend .no-list;
			@extend .trans-all-4;
			position: absolute;
			left: 20px;
			top: 300px;
			li{
				float: left;
				width: 15px;
				height: 15px;
				margin-right: 7px;
				border: 3px solid $cwhite;
				border-radius: 50%;
				cursor: pointer;
				div{
					@extend .flex;
					display: none;
					position: absolute;
					height: 30px;
					left: -10px;
					top: -45px;
					padding: 0 10px;
					color: $cwhite;
					font-size: $fsbig;
					text-transform: uppercase;
					white-space: nowrap;
					background-color: $ccorp;
					&:before{
						@include triangle(15px, $ccorp, down);
						display: block;
						position: absolute;
						content: "";
						bottom: -5px;
						left: 7px;
					}
				}
				&.slick-active,
				&:hover{
					border: 3px solid $ccorp;
				}
				&:hover{
					div{
						display: flex;
					}
				}
			}
		}
	}
	div.front_projects{
		height: 450px;
		padding: 10px;
		background-color: $cwhite;
		div.front_projects_item{
			height: 90px;
			margin-bottom: 10px;
			a{
				display: block;
				height: 100%;
				color: $cread;
				span.front_projects_item_img{
					display: block;
					float: left;
				}
				span.front_projects_item_texts{
					display: block;
					margin-left: 130px;
					padding-top: 8px;
					strong{
						display: block;
						height: 20px;
						margin-bottom: 2px;
						color: $cbase;
						font-size: $fsbig;
						font-weight: normal;
						overflow: hidden;
					}
					em{
						display: block;
						font-size: $fssmaller;
						font-style: normal;
						u{
							color: $ccorp;
							text-decoration: none;
						}
					}
				}
				&:hover{
					background-color: $ccorp;
					span.front_projects_item_texts{
						strong,
						em{
							color: $cwhite;
							u{
								color: $cwhite;
							}
						}
					}
				}
			}
		}
		div.see_all{
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			margin: 0;
			padding-right: 5px;
			text-align: right;
			a{
				height: 30px;
				padding: 0 30px 0 15px;
				color: $ccorp;
				font-size: $fssmall;
				text-align: left;
				text-transform: uppercase;
				background-color: $cwhite;
				border: 1px solid $ccorp;
				border-radius: 3px;
				background: url(../../themes/images/see_all_arrow.png) right 10px center no-repeat $cwhite;
				&:hover{
					color: $cwhite;
					background-color: $ccorp;
				}
			}
		}
	}
	div.front_news{
		div.front_news_item{
			height: 70px;
			margin-bottom: 5px;
			a{
				display: block;
				position: relative;
				height: 100%;
				color: $cbase;
				line-height: 1.3;
				span.front_news_item_date{
					@extend .flex;
					position: absolute;
					width: 65px;
					height: 20px;
					left: 0;
					top: 2px;
					transform: rotate(-90deg);
					transform-origin: 32px 32px;
					color: $cwhite;
					text-align: center;
					font-size: $fssmall;
					background-color: $cmain;
				}
				span.front_news_item_texts{
					display: block;
					margin-left: 30px;
					overflow: hidden;
					strong{
						display: block;
						margin-bottom: 3px;
						font-weight: normal;
						text-transform: uppercase;
					}
					em{
						color: $cmain;
						font-style: normal;
					}
				}
				&:after{
					@extend .flex;
					position: absolute;
					width: 15px;
					height: 15px;
					right: 2px;
					bottom: 4px;
					padding-left: 1px;
					color: $cwhite;
					font-size: $fssmall;
					font-family: $ffawesome;
					content: "\f0da";
					background-color: $cmain;
					border-radius: 50%;
					transition: all 0.4s ease;
				}
				&:hover{
					span.front_news_item_date,
					&:after{
						background-color: $ccorp;
					}
				}
			}
		}
		div.see_all{
			text-align: right;
			a{
				height: 30px;
				padding: 0 30px 0 15px;
				color: $ccorp;
				font-size: $fssmall;
				text-align: left;
				text-transform: uppercase;
				background-color: $cwhite;
				border: 1px solid $ccorp;
				border-radius: 3px;
				background: url(../../themes/images/see_all_arrow.png) right 10px center no-repeat $cwhite;
				&:hover{
					color: $cwhite;
					background-color: $ccorp;
				}
			}
		}
	}
	div.front_gallery{
		ul{
			@include clearfix;
			@extend .no-list;
			margin: 0 -5px;
			li{
				float: left;
				margin: 0 5px;
				a{
					display: block;
					position: relative;
					&:before{
						@extend .flex;
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						color: $cwhite;
						font-size: $fslarge;
						font-family: $ffawesome;
						z-index: 2;
						opacity: 0;
						transition: all 0.4s ease;
					}
					&:hover{
						&:before{
							opacity: 1;
						}
					}
				}
			}
		}
		&.photo_gallery{
			margin-bottom: 10px;
			ul{
				li{
					a{
						&:before{
							content: "\f00e";
						}
					}
				}
			}
		}
		&.video_gallery{
			margin-bottom: 10px;
			ul{
				li{
					a{
						&:before{
							content: "\f04b";
						}
					}
				}
			}
		}
	}
}
main{
	margin-bottom: 40px;
	div.content{
		@include clearfix;
	}
}