div.rights{
	margin-bottom: 15px;
	color: $cread;
	text-align: center;
}
div.artmedia{
	margin-bottom: 10px;
	text-align: center;
	color: $cread;
	a{
		color: $ccorp;
		&:hover{
			color: $cread;
		}
	}
}