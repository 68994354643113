div.partners_inside{
	div.partners_inside_img{
		float: left;
		width: 30%;
		margin-right: 15px;
		margin-bottom: 10px;
		z-index: 1;
		img{
			width: 100%;
			border-radius: 3px;
		}
	}
	div.partner_inside_texts{
		h3.partner_inside_title{
			margin-bottom: 3px;
			color: $ccorp;
			font-size: $fsbig;
			font-family: $ffmain;
			text-transform: uppercase;
		}
	}
}