section.footer_sitemap{
	nav{
		padding: 25px 0 20px;
		border-top: 5px solid $cgray;
		border-bottom: 1px solid $ccorp;
		> ul{
			@include clearfix;
			@extend .no-list;
			text-align: center;
			> li{
				display: inline-block;
				margin: 0 15px 10px;
				> a{
					display: block;
					font-size: $fsbig;
					text-transform: uppercase;
				}
				ul{
					padding: 10px;
					li{
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-bottom: 5px;
						&:before{
							display: block;
							content: "";
							width: 13px;
							height: 13px;
							margin-right: 10px;
							border: 1px solid $cgray;
							border-radius: 50%;
							transition: all 0.4s ease;
						}
						a{
							color: $cread;
						}
						&:hover{
							&:before{
								border: 1px solid $cmain;
							}
							> a{
								color: $cbase;
							}
						}
					}
				}
			}
		}
	}
}
div.sitemap{
	> ul{
		@include clearfix;
		@extend .no-list;
		width: 100%;
		> li{
			float: left;
			width: 20%;
			> a{
				display: block;
				color: $ccorp;
				font-size: $fsbig;
				text-transform: uppercase;
			}
			ul{
				padding: 10px;
				li{
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: 5px;
					&:before{
						display: block;
						content: "";
						width: 13px;
						height: 13px;
						margin-right: 10px;
						border: 1px solid $cgray;
						border-radius: 50%;
						transition: all 0.4s ease;
					}
					a{
						color: $cread;
					}
					&:hover{
						&:before{
							background-color: $ccorp;
							border: 1px solid $ccorp;
						}
						> a{
							color: $cbase;
						}
					}
				}
			}
		}
	}
}
footer{
	padding: 30px 0;
	text-align: center;
}