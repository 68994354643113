html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{
	position: relative;
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	outline: none;
}
body{
	color: #353535;
	font-size: 12px;
	font-family: "NotoPenekeko";
	line-height: 1.50;
}
blockquote,
q{
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after{
	content: "";
	content: none;
}
table{
	border-collapse: collapse;
	border-spacing: 0;
}
h1,
h2,
h3,
h4,
h5,
h6{
	line-height: 1.50;
}
a{
	text-decoration: none;
	outline: none !important;
}
a:hover,
a:focus{
	text-decoration: none;
}
ul,
ol,
ul ul,
ul ol,
ol ul,
ol ol{
	margin: 10px 10px 10px 25px;
	padding: 0;
	list-style: disc;
}
ul li,
ol li{
	padding-left: 5px;
}
ol{
	list-style: undefined;
}
img{
	height: auto;
	max-width: 100%;
	vertical-align: top;
}
p{
	margin-bottom: 10px;
}
hr{
	border-color: #f3f3f3;
}
input,
label,
select,
button,
textarea{
	margin: 0;
	border: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	background: none;
	line-height: 1;
	font-size: 12px;
	font-family: "NotoPenekeko";
	outline: none;
	box-shadow: none;
}
input:focus{
	outline: 0;
}
button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select{
	overflow: visible;
	width: auto;
	box-sizing: border-box;
}
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea,
button.gilaki{
	width: 100%;
	height: 40px;
	padding: 0 15px;
	color: #353535;
	background-color: #f3f3f3;
	border-radius: 3px;
}
button.gilaki{
	&:hover{
		color: #fff;
		background-color: #353535;
	}
}
input[type="checkbox"],
input[type="radio"]{
	width: 15px;
	height: 15px;
}
input[type="file"]{}
::-webkit-search-decoration{
	display: none;
}
::-webkit-file-upload-button{
	padding: 0;
	border: 0;
	background: none;
}
textarea{
	height: auto;
	padding: 15px;
	vertical-align: top;
	overflow: auto;
	resize: none;
}
select{}
select[multiple]{
	vertical-align: top;
}
button{
	width: auto;
}
::placeholder{
	color: #353535;
	font-size: 12px;
	font-family: "NotoPenekeko";
	opacity: 1;
}
label{
	display: block;
	font-weight: normal;
	line-height: 1.50;
	cursor: pointer;
}
div.artform_wrap{
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: #f3f3f3;
	cursor: pointer;
	&.artradio{
		border-radius: 50%;
		&:before{
			display: block;
			position: absolute;
			content: "";
			width: 8px;
			height: 8px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) scale(0);
			background-color: #fff;
			border-radius: 50%;
			opacity: 0;
			transition: all 0.2s ease;
		}
		&.checked{
			background-color: #a3a3a3;
			&:before{
				transform: translate(-50%, -50%) scale(1);
				opacity: 1;
			}
		}
		input[type="radio"]{
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
		}
		&.disabled{
			background-color: #fff;
			border: 1px solid #f3f3f3;
			input[type="radio"]{
				cursor: not-allowed;
			}
			&.checked{
				&:before{
					background-color: #f3f3f3;
					transform: translate(-50%, -50%) scale(1);
					opacity: 1;
				}
			}
		}
	}
	&.artchecker{
		border: 1px solid transparent;
		border-radius: 3px;
		&:before,
		&:after{
			position: absolute;
			content: "";
			width: 6px;
			height: 2px;
			left: 3px;
			top: 10px;
			transform: rotate(45deg);
			background-color: #fff;
			opacity: 0;
		}
		&:after{
			width: 10px;
			left: auto;
			top: 8px;
			right: 2px;
			transform: rotate(-45deg);
		}
		&.checked{
			background-color: #a3a3a3;
			&:before,
			&:after{
				opacity: 1;
			}
		}
		input[type="checkbox"]{
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
		}
		&.disabled{
			background-color: #fff;
			border: 1px solid #f3f3f3;
			input[type="checkbox"]{
				cursor: not-allowed;
			}
			&.checked{
				&:before,
				&:after{
					background-color: #f3f3f3;
					opacity: 1;
				}
			}
		}
	}
}
select{
	width: 100%;
}
span.select2{
	span.selection{
		span.select2-selection{
			height: 40px;
			background-color: #f3f3f3;
			border: none;
			border-radius: 3px;
			span.select2-selection__rendered{
				height: 100%;
				padding-left: 15px;
				padding-right: 30px;
				color: #777;
				line-height: 40px;
				vertical-align: middle;
				span.select2-selection__placeholder{
					color: #777;
				}
				span.select2-selection__clear{
					color: #777;
					font-size: 20px;
				}
			}
			span.select2-selection__arrow{
				width: 30px;
				height: 100%;
				top: 0;
				right: 0;
				b{
					display: none;
				}
				&:before,
				&:after{
					display: block;
					position: absolute;
					content: "";
					width: 7px;
					height: 2px;
					left: 50%;
					top: 50%;
					background-color: #777;
					transform: translate(-50%,-50%) rotate(45deg);
				}
				&:before{
					margin-left: -4px;
				}
				&:after{
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
	&.select2-container--open{
		span.selection{
			span.select2-selection{
				span.select2-selection__arrow{
					&:before{
						transform: translate(-50%,-50%) rotate(-45deg);
					}
					&:after{
						transform: translate(-50%,-50%) rotate(45deg);
					}
				}
			}
		}
	}
}
span.select2-container{
	span.select2-dropdown{
		background-color: #f3f3f3;
		border: none;
		box-shadow: 0 5px 3px 0 rgba(0,0,0,0.1);
		span.select2-results{
			ul{
				li{
					&.select2-results__option--highlighted{
						background-color: #777;
					}
				}
			}
		}
	}
}
div.file_uploader{
	input[type="file"]{
		position: absolute;
		left: 0;
		top: 0;
		width: 0;
		height: 0;
		opacity: 0;
	}
	label{
		height: 40px;
		color: #353535;
		background-color: #f3f3f3;
		border-radius: 3px;
		span{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			position: absolute;
			left: 0;
			top: 0;
			right: 90px;
			bottom: 0;
			padding-left: 15px;
			color: #a3a3a3;
		}
		strong{
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			width: 90px;
			height: 100%;
			top: 0;
			right: 0;
			color: #fff;
			font-weight: normal;
			background-color: #777;
			border-radius: 0 3px 3px 0;
		}
	}
}
.mask{
	-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}
.clear{
	display: block;
	clear: both;
	line-height: 0px;
}
.aligncenter{
	display: block;
	margin: 10px auto;
}
.alignleft{
	display: block;
	float: left;
	margin: 0 15px 5px 0;
}
.alignright{
	display: block;
	float: right;
	margin: 0 0 5px 15px;
}
.opacity:hover{
	opacity: 0.7;
}
.trans-2,
.trans-all-2 *{
	transition: all 0.2s ease;
}
.trans-3,
.trans-all-3 *{
	transition: all 0.3s ease;
}
.trans-4,
.trans-all-4 *{
	transition: all 0.4s ease;

}
.trans-5,
.trans-all-5 *{
	transition: all 0.5s ease;

}
.trans-no,
.trans-no-all *{
	transition: none;
}
ul.no-list,
ul.no-list ul,
ul.no-list ol,
ul.no-list li,
ol.no-list ol.no-list ol,
ol.no-list ul,
ol.no-list li{
	margin: 0;
	padding: 0;
	list-style: none;
}
.flex{
	display: flex;
	align-items: center;
	justify-content: center;
}
.no-select,
.no-select-all *{
	user-select: none;
}