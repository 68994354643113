div.attachments{
	h3{
		margin-bottom: 20px;
		color: $ccorp;
		font-size: $fsbig;
		font-family: $ffmain;
		text-transform: uppercase;
	}
	ul{
		@extend .no-list;
		margin-bottom: 30px;
		li{
			@include clearfix;
			margin-bottom: 15px;
			padding-left: 5px;
			padding-bottom: 15px;
			border-bottom: 1px solid $clight;
			em{
				float: left;
				color: $ccorp;
				font-size: 15px;
			}
			a{
				display: block;
				margin-left: 25px;
				color: $cmain;
			}
		}
	}
}