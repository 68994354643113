header{
	margin-bottom: 25px;
	padding-top: 35px;
	z-index: 50;
	div.logo{
		float: left;
		margin-left: 10px;
	}
	div.search{
		margin-bottom: 10px;
		padding-top: 20px;
		form{
			button{
				position: absolute;
				left: 13px;
				top: 50%;
				transform: translateY(-52%);
				color: $ccorp;
				font-size: 15px;
				&:hover{
					color: $cmain;
				}
			}
			input[type="search"]{
				height: 35px;
				padding-left: 35px;
				background-color: $cwhite;
				border: 1px solid $cgray;
				&:focus{
					border: 1px solid $ccorp;
				}
			}
		}
	}
	div.socials_lang{
		@include clearfix;
		float: right;
		div.socials{
			float: left;
			ul{
				@include clearfix;
				@extend .no-list;
				li{
					float: left;
					margin-left: 10px;
					&:first-child{
						margin-left: 0;
					}
					a{
						@extend .flex;
						width: 35px;
						height: 35px;
						color: $cmain;
						font-size: 15px;
						background-color: $cwhite;
						border: 1px solid $cgray;
						border-radius: 3px;
						&:hover{
							color: $cwhite;
							background-color: $ccorp;
							border: 1px solid $ccorp;
						}
					}
				}
			}
		}
		div.lang{
			float: left;
			width: 110px;
			height: 35px;
			margin-left: 10px;
			ul{
				@extend .no-list;
				position: absolute;
				width: 110px;
				height: 35px;
				left: 0;
				top: 0;
				background-color: $cwhite;
				border: 1px solid $cgray;
				border-radius: 3px;
				overflow: hidden;
				&:hover{
					border: 1px solid $ccorp;
				}
				li{
					&:last-child{
						padding-bottom: 10px;
					}
					a{
						display: flex;
						align-items: center;
						justify-content: flex-start;
						position: relative;
						height: 25px;
						padding-left: 15px;
						color: $cmain;
						text-transform: uppercase;
					}
					&.active{
						a{
							height: 33px;
							&:after{
								display: block;
								position: absolute;
								top: 50%;
								right: 10px;
								transform: translateY(-50%);
								font-family: $ffawesome;
								font-size: 15px;
								content: "\f107";
							}
						}
					}
					&:hover{
						a{
							color: $cbase;
						}
					}
				}
			}
		}
	}
}
section.main_menu{
	margin-bottom: 40px;
	z-index: 40;
	nav.navigation{
		height: 60px;
		padding-left: 25px;
		background-image: url(../../themes/images/menu_bak.png);
		/* background-color: $ccorp; */
		> ul{
			height: 100%;
			> li{
				height: 100%;
				margin-right: 30px;
				&:last-child{
					margin-right: 0;
				}
				> a{
					@extend .flex;
					position: relative;
					height: 100%;
					color: $cwhite;
					font-size: $fsbig;
					text-transform: uppercase;
					border-bottom: 3px solid transparent;
				}
				&.active,
				&:hover{
					> a{
						border-bottom: 3px solid $cwhite;
					}
				}
				ul{
					padding: 20px 20px 15px;
					background-color: $cwhite;
					border-radius: 0 0 3px 3px;
				}
			}
		}
	}
}