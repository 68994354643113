div.content{
	div.gallery_images{
		div.gallery_image_item{
			margin-bottom: 30px;
			a{
				display: block;
				position: relative;
				border-radius: 3px;
				overflow: hidden;
				&:before{
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					color: $ccorp;
					font-size: 20px;
					font-family: "Awesome";
					content: "\f00e";
					background-color: rgba(255,255,255,0.9);
					z-index: 1;
					opacity: 0;
					transition: all 0.4s ease;
				}
				&:hover{
					&:before{
						opacity: 1;
					}
				}
				img{
					width: 100%;
				}
			}
		}
	}
}