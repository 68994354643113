div.team{
	div.col-md-offset-4{
		float: none;
	}
	div.col-md-4{
		div.team_item{
			margin-bottom: 30px;
			text-align: center;
			div.team_item_img{
				margin-bottom: 10px;
				a{
					display: block;
					border-radius: 3px;
					overflow: hidden;
					&:hover{
						opacity: 0.7;
					}
				}
				img{
					border-radius: 3px;
				}
			}
			h3{
				color: $ccorp;
				font-size: $fsbig;
				font-family: $ffmain;
				text-transform: uppercase;
			}
			h4{
				color: $cread;
				font-size: $fsbase;
			}
		}
	}
}