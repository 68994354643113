div.team_inside{
	div.team_inside_img{
		margin-bottom: 20px;
		img{
			border-radius: 3px;
		}
	}
	div.team_inside_head{
		margin-bottom: 20px;
		h2{
			margin-bottom: 10px;
			color: $ccorp;
			font-size: $fsbig;
			font-family: $ffmain;
			text-transform: uppercase;
		}
		h3{
			margin-bottom: 5px;
			color: $cmain;
			font-size: $fsbig;
		}
		h4{
			margin-bottom: 5px;
			font-size: $fsbase;
			span{
				font-weight: bold;
			}
			a{
				color: $cbase;
				&:hover{
					color: $ccorp;
				}
			}
		}
	}
	div.team_inside_desc{
		@include clearfix;
		h3.team_inside_desc_title{
			margin-bottom: 5px;
			color: $ccorp;
			font-size: $fsbig;
			font-family: $ffmain;
			text-transform: uppercase;
		}
	}
}