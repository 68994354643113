div.breadcrumbs{
	margin: 20px 0;
	ul{
		@extend .no-list;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		font-size: 10px;
		li{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 5px 0 5px 10px;
			color: $ccorp;
			&:last-child{
				margin-right: 0;
				&:after{
					display: none;
				}
			}
			a{
				margin-right: 10px;
				color: $cbase;
				&:hover{
					color: $ccorp;
				}
			}
			&:after{
				display: block;
				color: $cbase;
				font-family: "Awesome";
				content: "\f105";
			}
		}
	}
}