div.faq{
	h3{
		margin-bottom: 20px;
		color: $ccorp;
		font-size: $fsbig;
		font-family: $ffmain;
		text-transform: uppercase;
	}
	ul{
		@extend .no-list;
		margin-bottom: 30px;
		li{
			@include clearfix;
			margin-bottom: 10px;
			padding-left: 5px;
			em{
				float: left;
				color: $ccorp;
				font-size: 15px;
			}
			a{
				display: block;
				margin-left: 25px;
				margin-bottom: 10px;
				color: $cmain;
			}
			&:hover{
				> a{
					color: $cbase;
				}
			}
			div.faq_content{
				@extend .trans-no;
				display: none;
				padding-bottom: 1px;
			}
		}
	}
}