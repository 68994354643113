main{
	div.content{
		div.page_body{
			@include clearfix;
			margin-bottom: 15px;
			div.inside_slide_item{
				height: 250px;
				img{
					max-width: none;
					height: 100%;
				}
			}
			table.tabs{
				margin-bottom: 30px;
				tr{
					td{
						position: relative;
						height: 35px;
						padding: 0;
						vertical-align: middle;
						background-color: $clight;
						a{
							@extend .flex;
							width: 100%;
							height: 35px;
						}
						&.active,
						&:hover{
							color: $cwhite;
							background-color: $ccorp;
							a{
								color: $cwhite;
							}
						}
					}
				}
			}
			table.graphs2{
				border-collapse: separate;
				border-spacing: 1px 1px;
				tr{
					td{
						padding: 7px 15px;
					}
				}
			}
			table.graphs_img{
				td{
					vertical-align: middle;
					padding: 5px 0;
				}
				img{
					margin-right: 10px;
				}
			}
		}
	}
}