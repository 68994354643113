div.list{
	div.list_item{
		margin-bottom: 15px;
		padding-bottom: 10px;
		border-bottom: 1px solid $clight;
		h3.list_item_title{
			margin-bottom: 3px;
			color: $ccorp;
			font-size: $fsbig;
			font-family: $ffmain;
			text-transform: uppercase;
			a{
				display: block;
				color: $ccorp;
				&:hover{
					color: $cbase;
				}
			}
		}
		date{
			display: block;
			margin-bottom: 5px;
			color: $cread;
			font-size: $fssmall;
		}
	}
}