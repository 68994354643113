div.see_all{
	margin: 20px 0;
	text-align: center;
	a{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		padding: 0 20px;
		color: $cwhite;
		font-family: $ffmain;
		background-color: $ccorp;
		border-radius: 30px;
		&:hover{
			background-color: $cmain;
		}
	}
}