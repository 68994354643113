div.pagination{
	@include clearfix;
	display: block;
	ul{
		@extend .no-list;
		text-align: center;
		li{
			display: inline-block;
			margin: 0 3px;
			a{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 28px;
				padding: 0 10px;
				color: $cmain;
				font-size: $fsbase;
				border-radius: 3px;
				em{
					font-size: 16px;
				}
			}
			&.active,
			&:hover{
				a{
					color: $cwhite;
					background-color: $ccorp;
				}
			}
		}
	}
	a.resp_prev,
	a.resp_next{
		display: none;
		align-items: center;
		justify-content: center;
		float: left;
		width: 40px;
		height: 40px;
		color: $cwhite;
		font-size: 16px;
		text-align: center;
		background-color: $ccorp;
		border-radius: 3px;
	}
	a.resp_next{
		float: right;
	}
}
@media(max-width: 768px){
	div.pagination{
		ul{
			display: none;
		}
		a.resp_prev,
		a.resp_next{
			display: flex;
		}
	}
}