main{
	div.content{
		div.project_inside{
			h2.project_item{
				margin-bottom: 10px;
				color: $cread;
				font-size: $fsbig;
				span{
					color: $cbase;
				}
			}
			h3.project_item{
				margin-bottom: 10px;
				color: $cread;
				font-size: $fsbase;
				span{
					color: $cbase;
				}
			}
			div.project_desc{
				color: $cread;
			}
			div.project_lists{
				margin-bottom: 10px;
				h3{
					margin-bottom: 5px;
					color: $cread;
					font-size: $fsbase;
				}
				ul{
					@extend .no-list;
					margin-left: 15px;
					li{
						position: relative;
						padding-left: 15px;
						&:before{
							display: block;
							position: absolute;
							content: "";
							width: 5px;
							height: 5px;
							left: 0;
							top: 7px;
							transform: rotate(45deg);
							background-color: $cmain;
						}
					}
				}
			}
			div.inside_video{
				padding-top: 56.25%;
			  margin-top: 40px;
        iframe{
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
				}
			}
		}
	}
}
