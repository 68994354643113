div.content{
	div.contact_wrap{
		div.contact_items{
			margin-bottom: 20px;
			&.contact_branch{
				margin-bottom: 15px;
				padding-bottom: 15px;
				border-bottom: 1px solid $clight;
			}
			h3{
				margin-bottom: 5px;
				color: $ccorp;
				font-size: $fsbig;
				font-family: $ffmain;
				text-transform: uppercase;
			}
			h4{
				margin-bottom: 5px;
				color: $cbase;
				font-size: $fsbase;
				font-family: $ffmain;
			}
			h5{
				margin-bottom: 15px;
				color: $cbase;
				font-size: $fsbase;
			}
			div.contact_item{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 10px;
				&:last-child{
					margin-bottom: 0;
				}
				em{
					flex-basis: 10%;
					color: $ccorp;
					font-size: 15px;
					text-align: center;
				}
				span{
					flex-basis: 90%;
					a{
						color: $cbase;
						&:hover{
							color: $ccorp;
						}
					}
				}
			}
		}
		div.contact_form{
			margin-bottom: 20px;
			form{
				div.contact_form_item{
					margin-bottom: 20px;
					textarea{
						height: 200px;
					}
					&.contact_form_submit{
						padding-right: 15px;
						text-align: right;
					}
				}
			}
		}
		div#map{
			border-radius: 3px;
		}
	}
}