// მთავარი მენიუ
nav.navigation{
	@include clearfix;
	> ul{
		@include clearfix;
		@extend .no-list;
		> li{
			float: left;
			margin-right: 15px;
			> a{
				color: $ccorp;
				transition: all 0.4s ease;
			}
			&.active,
			&:hover{
				> a{
					color: $cbase;
				}
			}
			ul{
				display: none;
				position: absolute;
				width: 250px;
				left: 0;
				top: 100%;
				padding-bottom: 20px;
				transition: none;
				li{
					position: relative;
					margin: 0;
					padding: 0;
					&:before,
					&:after{
						display: block;
						position: absolute;
						content: "";
						width: 100%;
						height: 1px;
						left: 0;
						bottom: 0;
						background-color: $clight;
						transition: all 0.8s ease;
					}
					&:after{
						width: 0;
						background-color: $ccorp;
					}
					a{
						display: flex;
						align-items: center;
						justify-content: flex-start;
						position: relative;
						padding: 10px 0;
						color: $cmain;
						transition: all 0.4s ease;
					}
					&.active,
					&:hover{
						> a{
							color: $ccorp;
						}
						&:after{
							width: 100%;
						}
					}
					ul{
						left: 100%;
						top: 0;
					}
				}
			}
		}
	}
}
// მთავარი მენიუ
// რესპონსივ მენიუ
div.resp_menu{
	display: none;
	position: absolute;
	width: 45px;
	height: 45px;
	top: 20px;
	right: 20px;
	z-index: 1000;
	div.resp_menu_toggle{
		width: 100%;
		height: 100%;
		background-color: $ccorp;
		border-radius: 50%;
		cursor: pointer;
		div{
			position: absolute;
			width: 20px;
			height: 1px;
			left: 50%;
			top: 50%;
			transform: translateX(-50%);
			background-color: $cwhite;
			transition: all 0.2s ease;
			&:first-child{
				top: 17px;
			}
			&:last-child{
				top: 29px;
			}
		}
		&.resp_menu_toggled{
			div{
				&:first-child,
				&:last-child{
					opacity: 0;
				}
				&:nth-child(2){
					transform: translateX(-50%) rotate(45deg);
				}
				&:nth-child(3){
					transform: translateX(-50%) rotate(-45deg);
				}
			}
		}
	}
	div.resp_menu_ul{
		display: none;
		position: absolute;
		width: 300px;
		top: 100%;
		right: 0;
		margin-top: 15px;
		padding: 15px;
		background-color: $ccorp;
		border-radius: 3px;
		&:before{
			@include triangle(20px, $ccorp, up);
			display: block;
			position: absolute;
			content: "";
			top: -10px;
			right: 12px;
		}
		> ul{
			@extend .no-list;
			> li{
				position: relative;
				padding: 10px 5px;
				border-bottom: 1px solid rgba(255,255,255,0.1);
				&:last-child{
					border-bottom: none;
				}
				> a{
					display: block;
					color: $cwhite;
				}
				div.plus_minus{
					position: absolute;
					width: 30px;
					height: 30px;
					top: 5px;
					right: 0;
					background-color: $ccorp;
					cursor: pointer;
					div.plus,
					div.minus{
						position: absolute;
						width: 11px;
						height: 1px;
						background-color: $cwhite;
						left: 50%;
						top: 15px;
						transform: translateX(-50%);
						transition: all 0.2s ease;
					}
					div.minus{
						transform: translateX(-50%) rotate(90deg);
					}
					&.plus_minus_toggled{
						div.minus{
							transform: translateX(-50%) rotate(0deg);
						}
					}
				}
				ul{
					display: none;
					padding: 10px 0 0 5px;
					li{
						position: relative;
						padding-bottom: 5px;
						&:before{
							display: block;
							position: absolute;
							content: "";
							left: 0;
							top: 10px;
							width: 5px;
							height: 1px;
							background-color: $cwhite;
						}
						a{
							display: block;
							margin-left: 15px;
							color: $cwhite;
						}
						ul{
							padding-left: 15px;
						}
						div.plus_minus{
							height: 20px;
							top: 0px;
							div.plus,
							div.minus{
								top: 10px;
							}
						}
					}
				}
			}
		}
	}
}
// რესპონსივ მენიუ
@media(max-width: 992px){
	nav.navigation{
		display: none;
	}
	div.resp_menu{
		display: block;
	}
}