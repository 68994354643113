div.partners{
	div.partner_item{
		@include clearfix;
		margin-bottom: 30px;
		div.partner_img{
			float: left;
			width: 30%;
			z-index: 1;
			a{
				&:hover{
					opacity: 0.7;
				}
			}
			img{
				width: 100%;
				border-radius: 3px;
			}
		}
		div.partner_texts{
			margin-left: 34%;
			h3.partner_title{
				margin-bottom: 3px;
				font-size: $fsbig;
				font-family: $ffmain;
				text-transform: uppercase;
				a{
					display: block;
					color: $ccorp;
					&:hover{
						color: $cbase;
					}
				}
			}
		}
	}
}