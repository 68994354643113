div.poll{
	h3.poll_head{
		margin-bottom: 15px;
		font-size: $fsbase;
	}
	form{
		div.poll_answers{
			@include clearfix;
			margin-left: 10px;
			margin-bottom: 15px;
		}
		div.poll_answer{
			@include clearfix;
			margin-bottom: 15px;
			div.artform_wrap{
				float: left;
			}
			label{
				margin-left: 30px;
				padding-top: 3px;
			}
		}
		div.poll_submit{
			margin-bottom: 10px;
			text-align: center;
		}
		div.poll_viewres{
			text-align: center;
			a{
				color: $ccorp;
				&:hover{
					color: $cbase;
				}
			}
		}
	}
	div.poll_results{
		div.poll_result{
			margin-bottom: 30px;
			h3{
				margin-bottom: 10px;
				font-size: $fsbase;
			}
			div.poll_result_track{
				height: 1px;
				background-color: $clight;
				div{
					height: 100%;
					background-color: $ccorp;
					span{
						display: flex;
						align-items: center;
						justify-content: center;
						position: absolute;
						height: 20px;
						left: 100%;
						bottom: -25px;
						padding: 0 8px;
						color: $cwhite;
						font-size: 10px;
						text-align: center;
						background-color: $ccorp;
						border-radius: 2px;
					}
				}
			}
		}
		h3.poll_total{
			margin: 35px 0 15px 5px;
			color: $cmain;
			font-size: $fsbase;
		}
		div.back_to_poll{
			text-align: center;
			&:before{
				display: inline-block;
				margin-right: 5px;
				content: "\f104";
				font-size: 14px;
				font-family: "Awesome";
			}
			a{
				color: $ccorp;
				&:hover{
					color: $cbase;
				}
			}
		}
	}
}