div.content{
	div.gallery_listing{
		div.gallery_item{
			margin-bottom: 30px;
			a{
				display: block;
				margin-bottom: 10px;
				overflow: hidden;
				border-radius: 3px;
				&:hover{
					opacity: 0.7;
				}
				img{
					width: 100%;
				}
			}
			h3{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				position: absolute;
				left: 0;
				top: 30px;
				padding: 3px 10px 3px 50px;
				font-size: $fsbigger;
				text-transform: uppercase;
				background-color: rgba(255,255,255,0.4);
			}
			&:hover{
				h3{
					padding-left: 60px;
				}
			}
		}
	}
}