@media (max-width: 1200px) {
  section.main_menu {
    nav.navigation {
      > ul {
        > li {
          margin-right: 20px;

          > a {
            font-size: 12px;
          }
        }
      }
    }
  }

  section.front_blocks {
    div.main_slider {
      ul.slick-dots {
        top: 240px;
      }
    }
  }
}

@media (max-width: 991px) {
  section.front_blocks {
    div.main_slider {
      margin-bottom: 30px;

      div.slick-slide {
        img {
          width: 100%;
        }
      }

      ul.slick-dots {
        top: calc(100% - 150px);
      }
    }
  }

  main {
    aside {
      div.banners {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          min-width: 360px;
        }
      }
    }
  }

  div.sitemap {
    > ul {
      > li {
        width: 25%;
      }
    }
  }
}

@media (max-width: 768px) {
  section.front_blocks {
    div.front_projects {
      margin-bottom: 25px;

      div.see_all {
        text-align: left;
      }
    }

    div.front_news {
      margin-bottom: 25px;

      div.see_all {
        text-align: left;
      }
    }

    div.front_gallery {
      &.photo_gallery,
      &.video_gallery {
        margin-bottom: 20px;
      }
    }
  }

  div.sitemap {
    > ul {
      > li {
        width: 50%;
      }
    }
  }
}

@media (max-width: 991px) {
  main {
    aside {
      div.banners {
        ul {
          li {
            min-width: 100%;
          }
        }
      }
    }
  }
}
