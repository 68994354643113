div.content{
	div.news_wrap{
		article{
			@include clearfix;
			margin-bottom: 30px;
			div.news_img{
				float: left;
				width: 170px;
				z-index: 5;
				a{
					display: block;
					border-radius: 3px;
					overflow: hidden;
					img{
						width: 100%;
					}
					&:hover{
						img{
							opacity: 0.7;
						}
					}
				}
			}
			div.news_texts{
				margin-left: 190px;
				h3.news_title{
					margin-bottom: 5px;
					font-size: $fsbig;
					font-family: $ffmain;
					text-transform: uppercase;
					a{
						display: block;
						color: $ccorp;
						&:hover{
							color: $cbase;
						}
					}
				}
				date{
					display: block;
					margin-bottom: 5px;
					color: $cread;
					font-size: $fssmall;
					em{
						float: left;
						margin-top: 1px;
					}
					span{
						display: block;
						margin-left: 16px;
					}
				}
				div.news_desc{
					color: $cmain;
				}
			}
			&:hover{
				div.news_texts{
					div.news_desc{
						color: $cmain;
					}
				}
			}
		}
	}
}
@media(max-width: 576px){
	div.content{
		div.news_wrap{
			article{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				div.news_img{
					width: 100%;
					margin-bottom: 10px;
				}
				div.news_texts{
					margin-left: 0px;
				}
			}
		}
	}
}