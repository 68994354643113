// ტეგები
html{
	-webkit-font-smoothing: antialiased;
} 
body{
	color: $cbase;
	font-size: $fsbase;
	font-family: $ffbase;
	background: url(../../themes/images/world.png) center 50px no-repeat;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx){
	-webkit-font-smoothing: subpixel-antialiased;
}
a{
	color: $ccorp;
	&:hover{
		color: $cbase;
	}
	&:focus{
		color: $ccorp;
	}
}
// ტეგები
// ფორმები
label{
	font-family: $ffbase;
}
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea{
	color: $cbase;
	font-family: $ffbase;
	background-color: $clight;
}
button.gilaki{
	color: $cwhite;
	background-color: $ccorp;
	font-family: $ffbase;
	&:hover{
		background-color: $cbase;
	}
}
::placeholder{
	color: $cbase;
	font-family: $ffbase;
}
header{
	div.search{
		form{
			input[type="search"]{
				&::placeholder{
					color: $cread;
					font-style: italic;
					transition: all 0.4s ease;
				}
			}
		}
	}
}
div.artform_wrap{
	background-color: $clight;
	&.artradio{
		&:before{
			background-color: $cwhite;
		}
		&.checked{
			background-color: $ccorp;
		}
		&.disabled{
			background-color: $cwhite;
			border: 1px solid $clight;
			&.checked{
				&:before{
					background-color: $clight;
				}
			}
		}
	}
	&.artchecker{
		&:before,
		&:after{
			background-color: $cwhite;
		}
		&.checked{
			background-color: $ccorp;
		}
		&.disabled{
			background-color: $cwhite;
			border: 1px solid $clight;
			&.checked{
				&:before,
				&:after{
					background-color: $clight;
				}
			}
		}
	}
}
span.select2{
	span.selection{
		span.select2-selection{
			background-color: $clight;
			span.select2-selection__rendered{
				color: $cbase;
				span.select2-selection__placeholder{
					color: $cbase;
				}
				span.select2-selection__clear{
					color: $cbase;
				}
			}
			span.select2-selection__arrow{
				&:before,
				&:after{
					background-color: $cbase;
				}
			}
		}
	}
}
span.select2-container{
	span.select2-dropdown{
		background-color: $clight;
		span.select2-results{
			ul{
				li{
					&.select2-results__option--highlighted{
						background-color: $cbase;
					}
				}
			}
		}
	}
}
div.file_uploader{
	label{
		color: $cbase;
		background-color: $clight;
		span{
			color: $cread;
		}
		strong{
			color: $cwhite;
			background-color: $ccorp;
		}
	}
}
// ფორმები
// მთავარი მენიუ
nav.navigation{
	> ul{
		> li{
			> a{
				color: $ccorp;
			}
			&.active,
			&:hover{
				> a{
					color: $cbase;
				}
			}
			ul{
				li{
					a{
						color: $cread;
					}
					&.active,
					&:hover{
						> a{
							color: $ccorp;
						}
					}
				}
			}
		}
	}
}
// მთავარი მენიუ
// რუკა
div.map{
	width: 100%;
	height: 300px;
}
// რუკა
h3.heads{
	margin-bottom: 15px;
	padding-left: 30px;
	color: $cmain;
	font-size: $fslarge;
	text-transform: uppercase;
	background: url(../../themes/images/heads_bul.png) left 7px no-repeat;
	a{
		color: $cmain;
		&:hover{
			color: $ccorp;
		}
	}
}
div.inside_slider{
	margin-bottom: 20px;
	a.slick-arrow{
		@extend .flex;
		position: absolute;
		width: 40px;
		height: 40px;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);
		color: rgba(255,255,255,0.7);
		font-size: 20px;
		background-color: rgba(0,0,0,0.3);
		border-radius: 50%;
		z-index: 2;
		&:hover{
			background-color: rgba(0,0,0,0.6);
		}
		em{
			margin-right: 3px;
		}
		&.slick-next{
			left: auto;
			right: 15px;
			em{
				margin-left: 3px;
				margin-right: 0;
			}
		}
	}
}