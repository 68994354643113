div.info{
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 3px;
	em.fa{
		display: flex;
		align-items: center;
		justify-content: center;
		float: left;
		width: 20px;
		height: 20px;
		color: $cwhite;
		border-radius: 50%;
		&.fa-info{
			background-color: $cinfo;
		}
		&.fa-exclamation{
			background-color: $cwarning;
		}
		&.fa-times{
			background-color: $cdanger;
		}
		&.fa-check{
			background-color: $csuccess;
		}
	}
	div.info_text{
		margin-left: 30px;
	}
}