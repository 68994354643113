div.content{
	article.news_static{
		div.featured_img{
			float: left;
			width: 250px;
			margin: 0 15px 10px 0;
			z-index: 1;
			a{
				display: block;
				border-radius: 3px;
				overflow: hidden;
				img{
					width: 100%;
				}
			}
		}
		div.page_body{
			margin-bottom: 20px;
			h3.news_title{
				margin-bottom: 5px;
				color: $ccorp;
				font-size: $fsbig;
				font-family: $ffmain;
			}
			div.page_head{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin-bottom: 10px;
				date{
					display: flex;
					align-items: center;
					justify-content: flex-start;
					color: $cread;
					font-size: $fssmall;
					em{
						margin-right: 8px;
					}
				}
				div.share{
					ul{
						@extend .no-list;
						display: flex;
						align-items: center;
						li{
							margin-left: 10px;
							&:first-child{
								margin-left: 0;
							}
							a{
								color: $ccorp;
								font-size: 15px;
								&:hover{
									color: $cbase;
								}
							}
						}
					}
				}
			}
			div.page_content{
				@include clearfix;
			}
		}
		div.page_media{
			h3{
				margin-bottom: 20px;
				padding-bottom: 10px;
				color: $ccorp;
				font-size: $fsbig;
				font-family: $ffmain;
				text-transform: uppercase;
				border-bottom: 1px solid $clight;
			}
			div.col-xs-6{
				margin-bottom: 30px;
				a{
					display: block;
					position: relative;
					border-radius: 3px;
					overflow: hidden;
				}
			}
			&.page_photo_gallery{
				div.col-md-8{
					a{
						display: block;
						img{
							border-radius: 3px;
						}
						&:hover{
							opacity: 0.7;
						}
					}
				}
				div.col-md-4{
					a{
						display: none;
						margin-bottom: 30px;
						img{
							border-radius: 3px;
						}
						&:nth-child(1),
						&:nth-child(2){
							display: block;
						}
						&:nth-child(2){
							&:after{
								@extend .flex;
								position: absolute;
								content: "";
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								color: $cwhite;
								font-size: 30px;
								background-color: rgba(80,187,78,0.7);
								border-radius: 3px;
							}
						}
						&:hover{
							opacity: 0.7;
						}
					}
				}
			}
			&.page_video_gallery{
				div.col-xs-6{
					a{
						&:before{
							display: flex;
							align-items: center;
							justify-content: center;
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							color: $cwhite;
							font-size: 25px;
							font-family: "Awesome";
							content: "\f04b";
							z-index: 1;
							transition: all 0.4s ease;
						}
						&:hover{
							&:before{
								opacity: 0.5;
							}
						}
					}
				}
			}
			&.page_attachments{
				ul{
					@extend .no-list;
					li{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 15px;
						padding-bottom: 15px;
						border-bottom: 1px solid $clight;
						a.attachment_title{
							flex-basis: 80%;
						}
						em.fa,
						a.attachment_download{
							flex-basis: 10%;
							font-size: 15px;
							text-align: center;
						}
					}
				}
			}
		}
	}
}
@media(max-width: 768px){
	div.content{
		article.news_static{
			div.featured_img{
				float: none;
				width: auto;
				margin-right: 0;
			}
		}
	}
}
@media(max-width: 360px){
	div.content{
		article.news_static{
			div.page_body{
				div.page_head{
					flex-direction: column;
					date{
						margin-bottom: 5px;
					}
				}
			}
		}
	}
}