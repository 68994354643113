div.search_results{
	@include clearfix;
	div.search_head{
		margin-bottom: 15px;
		padding-bottom: 20px;
		border-bottom: 1px solid $clight;
		div.search_texts{
			div.search_keyword{
				margin-bottom: 5px;
				color: $cbase;
				span{
					color: $cread;
				}
			}
		}
		div.search_form{
			form{
				@include clearfix;
				button{
					position: absolute;
					width: 40px;
					top: 0;
					right: 0;
					border-radius: 0 3px 3px 0;
				}
			}
		}
	}
	article{
		margin-bottom: 20px;
		h3.search_title{
			font-size: $fsbig;
			font-family: $ffmain;
			text-transform: uppercase;
			a{
				color: $cmain;
				&:hover{
					color: $cbase;
				}
				span.search_highlight{
					color: $cdanger;
				}
			}
		}
		div.search_desc{
			color: $cread;
			span.search_highlight{
				color: $cdanger;
			}
		}
		&:hover{
			div.search_desc{
				color: $cmain;
			}
		}
	}
}
@media(max-width: 768px){
	div.search_results{
		div.search_head{
			div.search_texts{
				margin-bottom: 15px;
			}
		}
	}
}