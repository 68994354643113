div.admin_toolbar{
	height: 35px;
	background-color: $cbase;
	a.admin_link{
		display: flex;
		align-items: center;
		justify-content: center;
		float: left;
		height: 100%;
		padding: 0 15px 0 10px;
		color: $cwhite;
		&:hover{
			background-color: $cmain;
		}
		em{
			margin-right: 10px;
		}
	}
}