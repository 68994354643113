main{
	div.content{
		div.project_listing{
			div.project_item{
				margin-bottom: 30px;
				text-align: center;
				div.project_item_img{
					margin-bottom: 3px;
					a{
						display: block;
						overflow: hidden;
						img{
							width: calc(100% + 20px);
							max-width: none;
						}
					}
				}
				h3{
					height: 60px;
					padding: 0 10px;
					font-size: $fsbig;
					text-transform: uppercase;
					overflow: hidden;
					a{
						padding: 3px 10px;
						color: $cwhite;
						line-height: 2.2;
						background-color: $ccorp;
						box-decoration-break: clone;
					}
				}
				&:hover{
					div.project_item_img{
						margin-bottom: 3px;
						a{
							img{
								margin-left: -20px;
							}
						}
					}
					h3{
						a{
							background-color: $cmain;
						}
					}
				}
			}
		}
	}
}