div.content{
	div.gallery_videos{
		div.gallery_video_item{
			margin-bottom: 30px;
			a{
				display: block;
				position: relative;
				margin-bottom: 10px;
				border-radius: 3px;
				overflow: hidden;
				&:before{
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					color: $cwhite;
					font-size: 55px;
					font-family: "Awesome";
					content: "\f04b";
					z-index: 1;
					transition: all 0.4s ease;
				}
				&:hover{
					&:before{
						opacity: 0.5;
					}
				}
				img{
					width: 100%;
				}
			}
			h3{
				color: $ccorp;
				font-family: $ffmain;
				font-size: $fsbig;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}
}
.mfp-fade.mfp-bg{
	opacity: 0;
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready{
	opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing{
	opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content{
	opacity: 0;
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content{
	opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content{
	opacity: 0;
}